import { yupResolver } from "@hookform/resolvers/yup";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { customToast } from "../../../components/notify/NotifyContainer";
import { tryCatchAsync } from "../../../utils";
import {} from "../services/charactersApiSlice";
import {
    useCreateHintMutation,
    useDeleteHintMutation,
    useGetAllHintsQuery,
    useUpdateHintMutation,
} from "../services/hintsApiSlice";
import { ICharacterHintInput } from "../types/interfaces";
import { HintsSchema } from "../validations";

interface IProps {
    selectedLang: string | null;
    isFormReset: boolean;
    setIsFormReset: (value: SetStateAction<boolean>) => void;
    isEditHintGroup: boolean;
    setIsEditHintGroup: (value: SetStateAction<boolean>) => void;
    afterSuccess?: () => void;
}

const MAX_HINT_TEXT_PER_LANG = 5;

const useCharacterHint = ({ isFormReset, setIsFormReset, setIsEditHintGroup, selectedLang, afterSuccess }: IProps) => {
    const { characterId } = useParams();
    const [isUpdate, setIsUpdate] = useState(false);
    const [selectedHintId, setSelectedHintId] = useState("");
    const defaultValues = {
        hintQ: "",
        hintA: "",
        formIsDirty: false,
        formIsValue: false,
    };
    const methods = useForm<ICharacterHintInput>({
        defaultValues: { ...defaultValues },
        resolver: yupResolver(HintsSchema(selectedLang)),
        mode: "all",
    });

    //>  ============== API ==============

    const [createHint, { isLoading: cHLoading, isSuccess: cHSuccess }] = useCreateHintMutation();
    const [updateHint, { isLoading: uHLoading, isSuccess: uHSuccess }] = useUpdateHintMutation();
    const [delHintRecord, { isSuccess: delSuccess }] = useDeleteHintMutation();

    const { data: hintData } = useGetAllHintsQuery(
        {
            page: 1,
            limit: 15,
            filter: [
                {
                    field: "character_uuid",
                    operator: "=",
                    value: characterId as string,
                },
            ],
            order_by: "prompt",
        },
        { skip: !characterId }
    );
    const filteredData = (hintData?.data.records || []).filter((item) => {
        if (!selectedLang) return item;
        return item.language === selectedLang;
    });

    const formIsDirty = methods.formState.isDirty;
    useEffect(() => {
        if (formIsDirty) {
            setIsFormReset(false);
        }
    }, [formIsDirty, setIsFormReset]);

    // ============== handler ==============

    const handleHintDelete = async (recordId: string) => {
        setSelectedHintId(selectedHintId);
        setIsFormReset(true);
        const [, error] = await tryCatchAsync(() => delHintRecord({ id: recordId }).unwrap());
        if (error) customToast.error(error as string);
    };

    const handleHintEdit = (id: string) => {
        setSelectedHintId(id);
        setIsFormReset(false);
        setIsEditHintGroup(true);
        setIsUpdate(true);
        const selectedHint = filteredData.find((item) => item.id === id);
        if (!selectedHint) return;
        methods.setValue("hintQ", selectedHint?.prompt);
        methods.setValue("hintA", selectedHint?.answer);
    };
    const handleEditCancel = () => {
        setIsFormReset(true);
    };
    const handleFormSubmit: SubmitHandler<ICharacterHintInput> = async (methods) => {
        const promptValue = methods.hintQ;
        const answerValue = methods.hintA;

        if (isUpdate) {
            const reqData = {
                id: selectedHintId,
                prompt: promptValue,
                answer: answerValue,
                language: selectedLang,
            };
            const [, updateError] = await tryCatchAsync(() => updateHint(reqData).unwrap());

            if (updateError) {
                customToast.error(updateError as string);
            }
        } else {
            const reqData = {
                character_uuid: characterId,
                prompt: promptValue,
                answer: answerValue,
                language: selectedLang,
            };
            await createHint(reqData);
        }

        if (afterSuccess) {
            afterSuccess();
        }
        setIsFormReset(true);
    };

    const handleClear = useCallback(() => {
        methods.reset();
        setIsUpdate(false);
        setIsEditHintGroup(false);
    }, [methods, setIsEditHintGroup]);

    // ============== useEffect ==============

    useEffect(() => {
        if (!isFormReset) return;
        handleClear();
    }, [isFormReset, handleClear]);

    useEffect(() => {
        if (cHSuccess) {
            customToast.success("successfully created");
            methods.reset();
        }
    }, [cHSuccess, methods]);
    useEffect(() => {
        if (uHSuccess) {
            customToast.success("successfully updated");
            methods.reset();
        }
    }, [uHSuccess, methods]);

    useEffect(() => {
        if (delSuccess) {
            customToast.success("delete success");
        }
    }, [delSuccess]);

    return {
        filteredData,
        uHLoading,
        cHLoading,
        handleFormSubmit,
        methods,
        formIsValid: methods.formState.isValid,
        formIsDirty,
        hintLimit: filteredData?.length < MAX_HINT_TEXT_PER_LANG,
        handleEditCancel,
        handleHintEdit,
        handleHintDelete,
        selectedHintId,
    };
};

export default useCharacterHint;

import { useTranslation } from "react-i18next";

import { RefactorRegularModal } from "../../../components/dialog";
import { usePopupContext } from "../../../contexts/popupProvider";
import { ENTERPRISE_PROD_PATH } from "../constants/constants";

const PasswordExpiredPopup = () => {
    const { Container, Title, SubTitle, Actions, Cancel, Confirm } = RefactorRegularModal;
    const { popups, setPopups } = usePopupContext();
    const navigateToEnterprise = () => {
        window.open(ENTERPRISE_PROD_PATH, "_blank");
    };

    const { t } = useTranslation();
    return (
        <RefactorRegularModal>
            <Container
                props={{
                    open: (popups.password_expired as boolean) || false,
                    onClose: () => setPopups({ password_expired: false }),
                }}
            >
                <Title>{t("COMMON.Password_Expired")}</Title>
                <SubTitle>{t("COMMON.Change_Password_Enterprise")}</SubTitle>
                <Actions>
                    <Cancel
                        color='warning'
                        text={t("COMMON.Skip") || "Skip"}
                        onClick={() => setPopups({ password_expired: false })}
                    ></Cancel>
                    <Confirm
                        text={t("COMMON.Change_Password_Now") || "Change Password Now"}
                        onClick={() => {
                            navigateToEnterprise();
                            setPopups({ password_expired: false });
                        }}
                        color='primary'
                    />
                </Actions>
            </Container>
        </RefactorRegularModal>
    );
};
export default PasswordExpiredPopup;

import { IContactLayoutValues, ISharedSpecLayout, ITextLayoutValues } from "../types";

const DEFAULT_STATE = {
    sortable: false,
    selectable: false,
    overLimitation: false,
};
const DEFAULT_VALUES = {
    textLayoutTitle: "",
    textLayoutDescription: "",
    contactLayoutTitle: "",
};
const CONTACT_LAYOUT_OPTIONS = [
    { type: "phone", label: "SALES.Phone_Number", placeholder: "SALES.Phone_Placeholder", value: "" },
    { type: "email", label: "SALES.Email", placeholder: "SALES.Email_Placeholder", value: "" },
    { type: "website", label: "SALES.Website", placeholder: "SALES.Website_Placeholder", value: "" },
];
const DEFAULT_TEXT_LAYOUT: ITextLayoutValues["textLayouts"][number] = {
    type: "text",
    title: "",
    description: "",
};
const DEFAULT_CONTACT_LAYOUT_VALUES: IContactLayoutValues["contactLayouts"][number] = {
    type: "contact",
    title: "",
    contacts: CONTACT_LAYOUT_OPTIONS.map((option) => ({
        type: option.type as "phone" | "email" | "website",
        value: "",
    })),
};
const DEFAULT_SHARED_SPEC_LAYOUT: ISharedSpecLayout["sharedSpecLayout"][number] = {
    id: "0",
    type: "sharedSpec",
    value: null,
};
export {
    CONTACT_LAYOUT_OPTIONS,
    DEFAULT_CONTACT_LAYOUT_VALUES,
    DEFAULT_SHARED_SPEC_LAYOUT,
    DEFAULT_STATE,
    DEFAULT_TEXT_LAYOUT,
    DEFAULT_VALUES,
};

import { ListItem, ListItemButton, ListItemIcon } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { INavItem } from "../../types/interfaces";
import classes from "./MenuItemComp.module.scss";

interface IProps {
    listItem: INavItem;
    children: ReactNode;
    handleSubMenuClick?: (event: React.MouseEvent<HTMLElement>) => void;
}
const MenuItemComp = ({ listItem, children, handleSubMenuClick }: IProps) => {
    if (!listItem.href || typeof listItem.href !== "string") {
        return (
            <ListItem disablePadding className={classes.listItem} onClick={handleSubMenuClick}>
                <ListItemButton>{children}</ListItemButton>
            </ListItem>
        );
    }
    return (
        <ListItem disablePadding className={classes.listItem}>
            <ListItemButton to={listItem.href} component={Link} className={classes.listBtn}>
                <ListItemIcon>{listItem?.icon && listItem.icon}</ListItemIcon>
                {children}
            </ListItemButton>
        </ListItem>
    );
};
export default MenuItemComp;

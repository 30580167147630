import { useCallback } from "react";

import { useGetChatHistoryByEnterpriseIdMutation } from "../services/charactersEnterpriseApiSlice";

interface IProps {
    enterpriseUserId: string;
    characterId: string;
    date: string;
    page: number;
    limit: number;
}
const useGetSubAccountHistoryRecords = ({ enterpriseUserId, characterId, date, page, limit }: IProps) => {
    const [fetchChatHistory, { data: chatData, isLoading: chatLoading, error }] =
        useGetChatHistoryByEnterpriseIdMutation();
    const chatRecords = chatData?.data?.data ?? [];
    const totalCount = chatData?.data?.total_count ?? 0;

    // const MOCK_CHARACTER_ID = "31c1956e-a3d7-430b-9425-450063ae6c63";
    // const MOCK_ENTERPRISE_ACCOUNT_ID = "72c37887-b35a-447c-872c-af79ffdc71ec";
    // const MOCK_DATE = "2025-03-03";
    const fetchHistory = useCallback(() => {
        if (characterId) {
            fetchChatHistory({
                enterprise_account_id: enterpriseUserId,
                day: date,
                page: page,
                limit: limit,
                character_id: characterId,
            });
        }
    }, [fetchChatHistory, enterpriseUserId, characterId, date, page, limit]);

    return {
        chatRecords,
        fetchHistory,
        chatLoading,
        error,
        totalCount,
    };
};

export default useGetSubAccountHistoryRecords;

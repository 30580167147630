import { SALE_API_URL } from "../../../../../../constants";
import { apiSlice } from "../../../../../../services";
import {
    CreateSharedSpecGroupRes,
    DeleteSharedSpecGroupRes,
    GetAllSharedSpecGroupByOrgIdRes,
    GetSharedSpecGroupByIdRes,
    ICreateSharedSpecGroupReq,
    IDeleteSharedSpecGroupReq,
    IGetAllSharedSpecGroupByOrgIdReq,
    IGetSharedSpecGroupByIdReq,
    IUpdateSharedSpecGroupReq,
    UpdateSharedSpecGroupRes,
} from "../types/interfaces";

const sharedSpecSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllSharedSpecGroupByOrgId: builder.query<GetAllSharedSpecGroupByOrgIdRes, IGetAllSharedSpecGroupByOrgIdReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/orgs/${reqBody.org_id}/specification_categories`,
                method: "GET",
                params: reqBody,
            }),
            providesTags: ["sharedSpecGroup"],
        }),

        getSharedSpecGroupById: builder.query<GetSharedSpecGroupByIdRes, IGetSharedSpecGroupByIdReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/specification_categories/${reqBody.id}`,
                method: "GET",
            }),
            providesTags: ["sharedSpecGroup"],
        }),

        deleteSharedSpecGroup: builder.mutation<DeleteSharedSpecGroupRes, IDeleteSharedSpecGroupReq>({
            query: (reqBody) => {
                const { org_id, ...body } = reqBody;
                return {
                    url: `${SALE_API_URL}/orgs/${org_id}/specification_categories`,
                    method: "DELETE",
                    body,
                };
            },
            invalidatesTags: ["sharedSpecGroup"],
        }),
        updateSharedSpecGroup: builder.mutation<UpdateSharedSpecGroupRes, IUpdateSharedSpecGroupReq>({
            query: (reqBody) => {
                const { spec_id, ...body } = reqBody;
                return {
                    url: `${SALE_API_URL}/specification_categories/${spec_id}`,
                    method: "PATCH",
                    body,
                };
            },
            invalidatesTags: ["sharedSpecGroup"],
        }),
        createSharedSpecGroup: builder.mutation<CreateSharedSpecGroupRes, ICreateSharedSpecGroupReq>({
            query: (reqBody) => {
                const { org_id, ...body } = reqBody;
                return {
                    url: `${SALE_API_URL}/orgs/${org_id}/specification_categories`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["sharedSpecGroup"],
        }),
    }),
});
export const {
    useGetAllSharedSpecGroupByOrgIdQuery,
    useDeleteSharedSpecGroupMutation,
    useUpdateSharedSpecGroupMutation,
    useCreateSharedSpecGroupMutation,
    useGetSharedSpecGroupByIdQuery,
} = sharedSpecSlice;

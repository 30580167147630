import { DateTimePickerProps } from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import clsx from "clsx";
import dayjs, { Dayjs } from "dayjs";

import { useDateContext } from "../../features/analyze/context";
import { PickerDate } from "../../features/analyze/context/DateProvider";
import styles from "./DateTimeDropdown.module.scss";

interface IProps extends DateTimePickerProps<PickerDate> {
    label?: string;
    title?: string;
    handleChange?: (newValue: PickerDate) => void;
    inputClassNames?: string;
}
const DateTimeDropdown = (props: IProps) => {
    const {
        title,
        defaultValue,
        label,
        value,
        handleChange,
        maxDate,
        minDate,
        disableFuture,
        format,
        views,
        inputClassNames,
        openTo,
    } = props;
    const { setDate } = useDateContext();
    const handleDateChange = (newValue: Dayjs | null) => {
        if (!newValue) return;
        setDate(newValue);
        handleChange?.(newValue);
    };

    return (
        <div className={styles.filterWrapper}>
            <div className={styles.selectName}>{title ? title + ":" : ""}</div>
            <div className={clsx(styles.input, inputClassNames)}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        format={format}
                        // format='YYYY / MM / DD - hh:mm' 需要分秒的話可以這樣寫
                        label={label}
                        views={views}
                        minDate={minDate}
                        maxDate={maxDate}
                        defaultValue={defaultValue ? dayjs(defaultValue) : null}
                        disableFuture={disableFuture}
                        value={value ? dayjs(value) : null}
                        onChange={handleDateChange}
                        openTo={openTo}
                    />
                </LocalizationProvider>
            </div>
        </div>
    );
};
export default DateTimeDropdown;

import { DateProvider } from "../../../analyze/context";
import SubAccountChatHistoryPreview from "./SubAccountChatHistoryPreview";

const SubAccountChatHistory = () => {
    return (
        <DateProvider rangeLimitDays={90}>
            <SubAccountChatHistoryPreview />
        </DateProvider>
    );
};

export default SubAccountChatHistory;

import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { usePageConfig } from "../../../hooks";
import { getLocalValueByKey } from "../../../utils";
import { useGetAllProjectCharactersQuery } from "../services";
import { IHistory, IMessage } from "../types";
import useGetSubAccountHistoryRecords from "./useGetSubAccountHistoryRecords";
const PAGE_LIMIT = 5;

const useGetSubAccountHistory = () => {
    //用角色選單，可以從知識庫中拿
    const { projectId } = useParams();

    const { handlePageChange, currentPage } = usePageConfig({
        pageLimit: PAGE_LIMIT,
    });
    const enterpriseUserId = getLocalValueByKey("enterprise_user_id") as string;
    const [selectedCharacter, setSelectedCharacter] = useState<string>("");
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(dayjs.utc());
    const [selectedMonth, setSelectedMonth] = useState<dayjs.Dayjs>(dayjs.utc());
    const [historyRecordId, setHistoryRecordId] = useState<string>("");
    const { fetchHistory, chatRecords, chatLoading, error, totalCount } = useGetSubAccountHistoryRecords({
        enterpriseUserId,
        characterId: selectedCharacter,
        date: selectedDate.format("YYYY-MM-DD"),
        page: currentPage,
        limit: PAGE_LIMIT,
    });

    const { data: projectCharacters } = useGetAllProjectCharactersQuery(
        { page: 1, limit: 100, project_id: projectId as string },
        { skip: !projectId }
    );
    const defaultCharacterId = projectCharacters?.data?.records?.[0]?.id;
    const characterOptions = projectCharacters?.data?.records?.map((record) => ({
        label: record.name,
        value: record.id,
    }));
    const handleCharacterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCharacter(e.target.value);
    };

    const historyData = chatRecords.map((record) => {
        return {
            id: record.history_id,
            charactor_id: record.charactor_id,
            created_at: dayjs.utc(record.created_at).local().format("YYYY-MM-DD HH:mm:ss"),
            firstText: record.messages[0].text,
        };
    });

    const handleSubmit = () => {
        fetchHistory();
    };

    const pageCount = Math.ceil(totalCount / PAGE_LIMIT);
    const handleEditClick = (id: string) => {
        setHistoryRecordId(id);
    };
    const chatRecordMessages = chatRecords?.find((item: IHistory) => item.history_id === historyRecordId)?.messages;
    const formattedMessages =
        chatRecordMessages?.map((r: IMessage) => ({
            id: r.id,
            body: r.text,
            sender: r.role === "bot" ? "System" : "User",
            timestamp: dayjs.utc(r.created_at).local().format("YYYY-MM-DD HH:mm:ss"),
            type: "text",
        })) || [];

    useEffect(() => {
        if (defaultCharacterId) {
            setSelectedCharacter(defaultCharacterId);
        }
    }, [defaultCharacterId]);

    useEffect(() => {
        if (defaultCharacterId) {
            fetchHistory();
        }
    }, [defaultCharacterId, fetchHistory]);

    return {
        setSelectedDate,
        totalCount,
        selectedDate,
        handlePageChange,
        pageCount,
        handleEditClick,
        currentPage,
        historyRecordId,
        handleSubmit,
        handleCharacterChange,
        selectedCharacter,
        characterOptions,
        historyData,
        chatLoading,
        error,
        setSelectedMonth,
        selectedMonth,
        formattedMessages,
    };
};

export default useGetSubAccountHistory;

import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import BackPage from "../../components/button/BackPage";
import { MainTabs } from "../../components/tab";
import { useDetailsCtx } from "../../features/sales/components/item/details/context/DetailsCtx";
import { useGetSaleLangOption } from "../../features/sales/hooks/useGetSaleLang";
interface IProps {
    setHasChangedPage?: Dispatch<SetStateAction<boolean>>;
    prevTab?: MutableRefObject<number>;
}
const DEFAULT_SALE_LANG = "zh-TW";
const SaleLanguageTabs = ({ setHasChangedPage, prevTab }: IProps) => {
    const navigate = useNavigate();
    const { orgId } = useParams();
    const { isSubmitDisabled } = useDetailsCtx();
    const { state, pathname } = useLocation();

    const [currentTab, setCurrentTab] = useState(0);
    const [searchParams] = useSearchParams();
    const { names } = useGetSaleLangOption();

    const tabRoutes =
        names?.map((row) => ({
            labelKey: row.name,
            href: row.lang,
        })) || [];
    const selectedLang = searchParams.get("lang") || DEFAULT_SALE_LANG;

    const findIdx = tabRoutes.findIndex((r) => r.href === searchParams.get("lang"));

    const handleChange = (newValue: number) => {
        if (prevTab) {
            prevTab.current = currentTab;
        }
        setCurrentTab(newValue);
        setHasChangedPage && setHasChangedPage(true);
    };

    useEffect(() => {
        if (findIdx !== -1) {
            localStorage.setItem("prevTab_init", findIdx.toString());
            setCurrentTab(findIdx);
            return;
        }
        setCurrentTab(0);
    }, [findIdx, selectedLang, prevTab, currentTab]);

    const backUrl = state ? `/sale/${state}` : `/sale/org/${orgId}/item`;
    const isNoLangTab = pathname.includes("/completed");

    return (
        <>
            <BackPage path={backUrl} />
            {isNoLangTab ? (
                <></>
            ) : (
                <MainTabs
                    currentTab={currentTab}
                    handleChange={handleChange}
                    tabsOnClick={(tabRoute) => navigate(`${pathname}?lang=${tabRoute.href}`)}
                    tabRoutes={tabRoutes}
                    isPill={true}
                    isDisabled={!isSubmitDisabled && pathname.includes("/details")}
                />
            )}
        </>
    );
};

export default SaleLanguageTabs;

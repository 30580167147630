import { List, ListItem, ListItemText, Pagination } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import avatar from "../../../../assets/icons/account-circle.svg";
import { MAlert } from "../../../../components/notify";
import { IHistoryData } from "../../types";
import styles from "./SubAccountHistoryList.module.scss";
interface IProps {
    histories: IHistoryData[];
    handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
    pageCount: number;
    searchParams: URLSearchParams;
    handleEditClick: (id: string) => void;
    currentPage: number;
    historyCount: number;
}

const SubAccountHistoryList = ({
    histories,
    historyCount,
    handlePageChange,
    pageCount,
    currentPage,
    handleEditClick,
}: IProps) => {
    const { t } = useTranslation();

    if (historyCount === 0) {
        return (
            <>
                <MAlert severity='info' sx={{ bgcolor: "transparent" }}>
                    {t("RECORD.No_Record")}
                </MAlert>
            </>
        );
    }

    return (
        <>
            <List dense className={styles.customList}>
                {histories &&
                    histories.map((item, index) => {
                        return (
                            <Fragment key={index}>
                                <ListItem className={styles.listItem}>
                                    <div className={styles.rowText} onClick={() => handleEditClick(item.id || "")}>
                                        <div className={styles.avatar}>
                                            <img src={avatar} alt='avatar' />
                                        </div>
                                        <div className={styles.message}>
                                            <p>{item.created_at}</p>
                                            <p>{item.firstText}</p>
                                        </div>
                                    </div>
                                </ListItem>
                            </Fragment>
                        );
                    })}
                {histories && histories.length === 0 && (
                    <ListItem>
                        <ListItemText className={styles.rowText} secondary={"No record found ..."} />
                    </ListItem>
                )}
            </List>
            <div className={styles.paginationWrapper}>
                <Pagination
                    count={pageCount}
                    page={currentPage || 1}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                />
            </div>
        </>
    );
};
export default SubAccountHistoryList;

import { SALE_API_URL } from "../../../constants";
import { apiSlice } from "../../../services";
import type {
    ICreateSaleItemImagesReq,
    ICreateSaleItemImagesRes,
    ICreateSaleItemReq,
    ICreateSaleItemRes,
    IDeleteItemReq,
    IDeleteItemRes,
    IDeleteOrgItemReq,
    IDeleteOrgItemRes,
    IDeleteSaleItemImagesReq,
    IDeleteSaleItemImagesRes,
    IEditSaleItemReq,
    IGetSaleItemReq,
} from "../types";

export const saleItemApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createSaleItem: builder.mutation<ICreateSaleItemRes, ICreateSaleItemReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/orgs/${reqBody.org_id}/items`,
                body: reqBody.formData,
                method: "POST",
            }),
            invalidatesTags: ["SalesItems"],
        }),

        getSaleItem: builder.query<ICreateSaleItemRes, IGetSaleItemReq>({
            query: (params) => ({
                url: `${SALE_API_URL}/items/${params.item_id}`,
                method: "GET",
                params,
            }),
            providesTags: ["SalesItems"],
        }),
        editSaleItem: builder.mutation<ICreateSaleItemRes, IEditSaleItemReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/items/${reqBody.item_id}`,
                body: reqBody.data,
                method: "PUT",
            }),
            invalidatesTags: ["SalesItems"],
        }),
        deleteSaleItemImages: builder.mutation<IDeleteSaleItemImagesRes, IDeleteSaleItemImagesReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/items/${reqBody.item_id}/images`,
                method: "DELETE",
                body: reqBody,
            }),
            invalidatesTags: ["SalesItems"],
        }),
        deleteOrgItem: builder.mutation<IDeleteOrgItemRes, IDeleteOrgItemReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/orgs/${reqBody.org_id}/items`,
                method: "DELETE",
                body: { ids: reqBody.ids },
            }),
            invalidatesTags: ["SalesItems", "SalesCategories"],
        }),
        deleteItem: builder.mutation<IDeleteItemRes, IDeleteItemReq>({
            query: (reqBody) => ({
                url: `${SALE_API_URL}/items/${reqBody.item_id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["SalesItems"],
        }),
        createSaleItemImages: builder.mutation<ICreateSaleItemImagesRes, ICreateSaleItemImagesReq>({
            query: (reqBody) => {
                return {
                    url: `${SALE_API_URL}/items/${reqBody.item_id}/images`,
                    method: "POST",
                    body: reqBody.formData,
                };
            },
            invalidatesTags: ["SalesItems"],
        }),
    }),
});
export const {
    useCreateSaleItemMutation,
    useGetSaleItemQuery,
    useEditSaleItemMutation,
    useDeleteSaleItemImagesMutation,
    useDeleteOrgItemMutation,
    useCreateSaleItemImagesMutation,
    useDeleteItemMutation,
} = saleItemApiSlice;

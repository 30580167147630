import { Dispatch, MutableRefObject, SetStateAction, useEffect } from "react";
import { UseFieldArrayReplace, UseFormReturn } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { useAppSelector } from "../../../../../../hooks";
import { selectLangs } from "../../../../../langs/services";
import { IContactLayoutValues, ILanguageFormState, ISharedSpecLayout, ITextLayoutValues } from "../types";
interface ILayoutMethods {
    textLayoutMethods: UseFormReturn<ITextLayoutValues>;
    contactLayoutMethods: UseFormReturn<IContactLayoutValues>;
}
interface ILayoutFields {
    replaceTextLayout: UseFieldArrayReplace<ITextLayoutValues, "textLayouts">;
    replaceContactLayout: UseFieldArrayReplace<IContactLayoutValues, "contactLayouts">;
}
interface ISharedSpecState {
    sharedSpecLayout: ISharedSpecLayout["sharedSpecLayout"];
    setSharedSpecLayout: Dispatch<SetStateAction<ISharedSpecLayout["sharedSpecLayout"]>>;
}

interface ISyncLanguageState {
    hasChangedPage: boolean;
    setHasChangedPage: Dispatch<SetStateAction<boolean>>;
    prevTab: MutableRefObject<number>;
}

interface ILanguageFormMethods {
    getLanguageState: (lang: string) => ILanguageFormState | undefined;
    saveLanguageState: (lang: string, state: ILanguageFormState) => void;
    isDirty: boolean;
    handleDirty: (bool: boolean) => void;
}
interface IProps extends ILayoutMethods, ILayoutFields, ISharedSpecState, ISyncLanguageState, ILanguageFormMethods {}
const useLanguageFormState = ({
    textLayoutMethods,
    contactLayoutMethods,
    handleDirty,
    sharedSpecLayout,
    hasChangedPage,
    getLanguageState,
    saveLanguageState,
    replaceTextLayout,
    replaceContactLayout,
    setSharedSpecLayout,
    setHasChangedPage,
    isDirty,
    prevTab,
}: IProps) => {
    const [searchParams] = useSearchParams();
    const selectedLang = searchParams.get("lang") || "zh-TW";
    const langs = useAppSelector(selectLangs);
    // 當表單值改變時觸發 handleDirty，讓表單內容可以被儲存
    // (sharedSpecLayout 的值改變會在 SharedSpecLayoutForm 中觸發 handleDirty)
    useEffect(() => {
        const textSubscription = textLayoutMethods.watch(() => handleDirty(true));
        const contactSubscription = contactLayoutMethods.watch(() => handleDirty(true));

        return () => {
            textSubscription.unsubscribe();
            contactSubscription.unsubscribe();
        };
    }, [textLayoutMethods, contactLayoutMethods, handleDirty, sharedSpecLayout]);
    // 當語言改變時載入對應的狀態
    useEffect(() => {
        if (!hasChangedPage) return;
        const savedState = getLanguageState(selectedLang);
        const prevState = getLanguageState(langs[prevTab.current]);
        if (!prevState) return;
        if (savedState) {
            replaceTextLayout(savedState.textLayouts);
            replaceContactLayout(savedState.contactLayouts);
            setSharedSpecLayout(savedState.sharedSpecLayout);
        } else {
            textLayoutMethods.reset({ textLayouts: [] });
            contactLayoutMethods.reset({ contactLayouts: [] });
            setSharedSpecLayout([]);
        }
        setHasChangedPage(false);
    }, [
        selectedLang,
        getLanguageState,
        hasChangedPage,
        setHasChangedPage,
        replaceTextLayout,
        replaceContactLayout,
        textLayoutMethods,
        contactLayoutMethods,
        setSharedSpecLayout,
        sharedSpecLayout,
        langs,
        prevTab,
    ]);

    // 當表單內容改變時儲存狀態
    useEffect(() => {
        if (!isDirty) return;
        const currentState = {
            textLayouts: textLayoutMethods.getValues().textLayouts,
            contactLayouts: contactLayoutMethods.getValues().contactLayouts,
            sharedSpecLayout,
        };
        saveLanguageState(selectedLang, currentState);
        handleDirty(false);
    }, [
        selectedLang,
        saveLanguageState,
        textLayoutMethods,
        contactLayoutMethods,
        sharedSpecLayout,
        isDirty,
        handleDirty,
    ]);
};
export default useLanguageFormState;

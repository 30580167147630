import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { InnerTabs } from "../../components/tab";
import { ILinkTabProps } from "../../types/interfaces";
import { isProduction } from "../../utils";
import styles from "./SubAccountTabs.module.scss";
const prodRoute = [
    { labelKey: "CH.ChatRoom", href: "chatroom", initHref: "chatroom" },
    { labelKey: "CH.Chat_History", href: "history" },
];

const tabRoutes: ILinkTabProps[] = isProduction ? prodRoute : [...prodRoute];

const SubAccountTabs = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const { pathname } = useLocation();
    const pathnameSplit = pathname?.split("/");
    const currentRoute = pathnameSplit[pathnameSplit?.length - 1] || "";

    const [currentTab, setCurrentTab] = useState(0);

    const findIdx = tabRoutes.findIndex((r) => r.href === currentRoute);

    const handleChange = (newValue: number) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        // if findIdx is not -1, means it's project dashboard index page, show current tab === Dashboard
        if (findIdx !== -1) {
            setCurrentTab(findIdx);
            return;
        }
        setCurrentTab(0);
    }, [findIdx, currentRoute]);
    return (
        <>
            <InnerTabs
                currentTab={currentTab}
                handleChange={handleChange}
                tabsOnClick={(tabRoute) => {
                    if (tabRoute.initHref) {
                        navigate(`/projects/${projectId}/sub-account/${tabRoute.initHref}`);
                    } else {
                        navigate(`/projects/${projectId}/sub-account/${tabRoute.href}`);
                    }
                }}
                tabRoutes={tabRoutes}
                className={styles.tab}
            />
        </>
    );
};

export default SubAccountTabs;

import { AuthTokens } from "aws-amplify/auth";
import { useEffect } from "react";
import { Outlet, useLocation, useMatch, useNavigate } from "react-router-dom";

import PageLoader from "../../../components/loader/PageLoader";
import PopupProvider from "../../../contexts/popupProvider";
import { useAmzChatWidget, useAppDispatch, useAppSelector } from "../../../hooks";
import { awsFetchUserInfo, awsGetCurrentUser, currentSession } from "../../../lib/aws";
import { getLocalValueByKey } from "../../../utils";
import { useCheckSaleAccountMutation } from "../../sales/services";
import { useAmazeSignInMutation, useAmzLogoutMutation, useCheckUserStatusMutation } from "../services";
import {
    postEnterpriseLogin,
    postLogin,
    postLogout,
    postSalesLogin,
    selectCurrentUser,
    selectIsLogin,
    setCurrentUser,
} from "../services/authSlice";
import { ICurrentUser } from "../types/interfaces";
import { getUserRole } from "../utils";

const VerifyLogin = () => {
    useAmzChatWidget();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isFromTempLogin = pathname === "/temp-login";

    // Enterprise Login
    const backLoginPath = isFromTempLogin ? "/temp-login" : "/login";
    const matchEnterpriseLoginPath = useMatch("/enterprise-login");
    const amzAccessTokenFromEnterpriseLogin = getLocalValueByKey("amaze_accessToken");
    const amzRefreshTokenFromEnterpriseLogin = getLocalValueByKey("amaze_refreshToken");
    const enterpriseAccountId = getLocalValueByKey("enterprise_account_id");
    const enterpriseRoles = getLocalValueByKey("enterprise_roles");
    const enterpriseUserId = getLocalValueByKey("enterprise_user_id");
    const isEnterpriseLogin =
        !!matchEnterpriseLoginPath || !!(amzAccessTokenFromEnterpriseLogin && amzRefreshTokenFromEnterpriseLogin);

    // Sales Login
    const matchSalesLoginPath = useMatch("/sale/login");
    const salesLoginPath = matchSalesLoginPath ? "/sale/login" : "/login";
    const salesAccessToken = getLocalValueByKey("sale_accessToken");
    const salesRefreshToken = getLocalValueByKey("sale_refreshToken");
    const isSalesLogin = !!matchSalesLoginPath || !!(salesAccessToken && salesRefreshToken);

    const isLogin = useAppSelector(selectIsLogin);
    const currentUser = useAppSelector(selectCurrentUser);

    const [amazeSignIn, { isLoading }] = useAmazeSignInMutation();
    const [userStatus, { isLoading: checkUserStatusLoading }] = useCheckUserStatusMutation();
    const [fetchAmzLogout] = useAmzLogoutMutation();
    const [salesUserStatus, { isLoading: checkSalesUserStatusLoading }] = useCheckSaleAccountMutation();

    //handle sales login
    useEffect(() => {
        const getSalesSession = async () => {
            try {
                dispatch(postSalesLogin({ accessToken: salesAccessToken, refreshToken: salesRefreshToken }));
                const res = await salesUserStatus().unwrap();
                if (res?.data) {
                    const userInfo: ICurrentUser = {
                        accountId: "salesAccount",
                        email: res.data.email,
                        bindLine: false,
                        username: res.data.display_name,
                        enterpriseAccountId: null,
                        isFirstTimeLogin: false,
                        salesAccountId: res.data.id,
                        role: getUserRole(res?.data?.email), // sales 暫時不會用到 role, 先使用 amz 的 role, 但沒有任何作用
                        org_id: res.data.org_id,
                        enterpriseRoles: null,
                        enterpriseUserId: null,
                    };
                    dispatch(setCurrentUser(userInfo));
                    // navigate("/sale/org/" + res.data.org_id + "/item");重整並留在原頁面
                }
            } catch (error) {
                navigate(salesLoginPath);
                dispatch(postLogout());
            }
        };

        if (isSalesLogin && !currentUser && salesAccessToken && salesRefreshToken) {
            getSalesSession();
        }
    }, [
        isLogin,
        isSalesLogin,
        currentUser,
        dispatch,
        isEnterpriseLogin,
        salesAccessToken,
        salesRefreshToken,
        salesLoginPath,
        navigate,
        salesUserStatus,
    ]);

    // handle enterprise login & handle enterprise user refresh page
    useEffect(() => {
        const getAmzSession = async () => {
            try {
                dispatch(
                    postEnterpriseLogin({
                        accessToken: amzAccessTokenFromEnterpriseLogin,
                        refreshToken: amzRefreshTokenFromEnterpriseLogin,
                    })
                );
                const res = await userStatus().unwrap();
                if (res) {
                    const userInfo: ICurrentUser = {
                        accountId: res.account_id,
                        isFirstTimeLogin: res.is_first_time_login,
                        bindLine: res.bind_line,
                        email: "res.email",
                        username: "res.username",
                        enterpriseAccountId: enterpriseAccountId as string,
                        salesAccountId: null,
                        role: "enterprise_user",
                        enterpriseRoles: enterpriseRoles ? JSON.parse(enterpriseRoles) : null,
                        enterpriseUserId: enterpriseUserId ? enterpriseUserId : null,
                    };
                    dispatch(setCurrentUser(userInfo));
                }
                return;
            } catch (err) {
                navigate(backLoginPath);
                dispatch(postLogout());
            }
        };
        if (
            !isSalesLogin &&
            !isLogin &&
            !currentUser &&
            isEnterpriseLogin &&
            amzAccessTokenFromEnterpriseLogin &&
            amzRefreshTokenFromEnterpriseLogin
        ) {
            getAmzSession();
        }
    }, [
        amzAccessTokenFromEnterpriseLogin,
        amzRefreshTokenFromEnterpriseLogin,
        backLoginPath,
        currentUser,
        dispatch,
        enterpriseAccountId,
        isEnterpriseLogin,
        isLogin,
        navigate,
        userStatus,
        isSalesLogin,
        enterpriseRoles,
        enterpriseUserId,
    ]);

    // handle aws login & handle aws user refresh page
    useEffect(() => {
        const getAwsSession = async () => {
            try {
                const sessionRes: AuthTokens | null = await currentSession();

                const awsAccessToken = sessionRes?.accessToken.toString();

                if (!awsAccessToken) {
                    navigate(backLoginPath);
                    dispatch(postLogout());
                    fetchAmzLogout();

                    return;
                }
                const result = await awsGetCurrentUser();
                const awsUserInfo = await awsFetchUserInfo();
                const signinRes = await amazeSignIn({ access_token: awsAccessToken }).unwrap();

                if (signinRes && awsUserInfo && result) {
                    const userInfo: ICurrentUser = {
                        accountId: signinRes.account_id,
                        isFirstTimeLogin: signinRes.is_first_time_login,
                        bindLine: signinRes.bind_line,
                        email: awsUserInfo?.email || "",
                        username: result.username,
                        enterpriseAccountId: null,
                        salesAccountId: null,
                        role: getUserRole(awsUserInfo?.email),
                        enterpriseRoles: null,
                        enterpriseUserId: null,
                    };
                    dispatch(postLogin());
                    dispatch(setCurrentUser(userInfo));
                }
            } catch (error) {
                navigate(backLoginPath);
                dispatch(postLogout());
                fetchAmzLogout();
            }
        };

        if (!isSalesLogin && !isEnterpriseLogin && !isLogin && !currentUser) {
            getAwsSession();
        }
    }, [
        amazeSignIn,
        backLoginPath,
        currentUser,
        dispatch,
        fetchAmzLogout,
        isEnterpriseLogin,
        isLogin,
        navigate,
        isSalesLogin,
    ]);

    // useEffect(() => {
    //     console.log("[Dev Only] AWS Hub");
    //     const unsubscribe = Hub.listen("auth", ({ payload, ...others }) => {
    //         console.log({ payload, others });
    //         switch (payload.event) {
    //             case "signInWithRedirect":
    //                 console.log("signInWithRedirect");
    //                 break;
    //             case "signInWithRedirect_failure":
    //                 console.log("An error has ocurred during the OAuth flow.");
    //                 break;
    //             case "customOAuthState":
    //                 console.log("customOAuthState");
    //                 break;
    //         }
    //     });
    //
    //     return unsubscribe;
    // }, []);

    if (isLoading || checkUserStatusLoading || checkSalesUserStatusLoading) {
        return <PageLoader loading={true} />;
    }
    return (
        <>
            <PopupProvider>
                <Outlet />
            </PopupProvider>
        </>
    );
};
export default VerifyLogin;

import { apiSlice } from "../../../services/api/apiSlice";
import {
    IChatHistoryDownloadWithEnterpriseReq,
    IChatHistoryRes,
    IGetChatHistoryByEnterpriseIdReq,
    IGetChatHistoryByEnterpriseIdRes,
} from "../types/interfaces";

export const charactersEnterpriseApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // > 下載角色對話紀錄(企業用戶)
        downloadChatHistoryWithEnterprise: builder.mutation<IChatHistoryRes, IChatHistoryDownloadWithEnterpriseReq>({
            query: (reqData) => ({
                url: "/chat_history/export_messages/by_enterprise_id",
                method: "POST",
                body: { ...reqData },
            }),
        }),
        //搜尋
        getChatHistoryByEnterpriseId: builder.mutation<
            IGetChatHistoryByEnterpriseIdRes,
            IGetChatHistoryByEnterpriseIdReq
        >({
            query: (reqData) => ({
                url: "/chat_history/list_records/by_enterprise_id",
                method: "POST",
                body: reqData,
            }),
        }),
    }),
});

export const { useDownloadChatHistoryWithEnterpriseMutation, useGetChatHistoryByEnterpriseIdMutation } =
    charactersEnterpriseApiSlice;

import { Action, IState } from "./types";

const reducer = (state: IState, action: Action) => {
    switch (action.type) {
        case "ENABLE_SORTABLE":
            return { ...state, sortable: true };
        case "ENABLE_SELECTABLE":
            return { ...state, selectable: true };
        case "DISABLE_SORTABLE":
            return { ...state, sortable: false };
        case "DISABLE_SELECTABLE":
            return { ...state, selectable: false };
        case "OVER_LIMITATION":
            return { ...state, overLimitation: true };
        case "UNDER_LIMITATION":
            return { ...state, overLimitation: false };
        default:
            throw new Error("Invalid action");
    }
};

export default reducer;

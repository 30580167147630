import React from "react";

const SalesStoreManagePage = React.lazy(() => import("../../../pages/sales/store/StorePage"));
const StoreCreatePage = React.lazy(() => import("../../../pages/sales/store/StoreCreatePage"));
const StoreImportItemsPage = React.lazy(() => import("../../../pages/sales/store/StoreImportItemsPage"));
const DevicePage = React.lazy(() => import("../../../pages/sales/store/DevicePage"));
const DeviceDetailPage = React.lazy(() => import("../../../pages/sales/store/DeviceDetailPage"));
const StoreSearchTestToolPage = React.lazy(() => import("../../../pages/sales/store/StoreSearchTestToolPage"));

const StoreRoutes = [
    {
        path: "",
        children: [
            { path: "", element: <SalesStoreManagePage /> },
            {
                path: "create",
                children: [
                    {
                        path: "",
                        element: <StoreCreatePage />,
                    },
                ],
            },
            {
                path: ":storeId",
                children: [
                    {
                        path: "edit",
                        element: <StoreCreatePage />,
                    },
                    {
                        path: "create",
                        element: <StoreCreatePage />,
                    },
                    {
                        path: "import-items",
                        element: <StoreImportItemsPage />,
                    },
                    {
                        path: "device-management",
                        element: <DevicePage />,
                        children: [
                            {
                                path: ":deviceId",
                                element: <DeviceDetailPage />,
                            },
                        ],
                    },
                    {
                        path: "search-test-tool",
                        element: <StoreSearchTestToolPage />,
                    },
                ],
            },
        ],
    },
];

export default StoreRoutes;

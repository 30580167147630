import React from "react";

import { SalesLayout } from "../../features/sales/components/layout";
import ItemRoutes from "./item";
import StoreRoutes from "./store/StoreRoutes";

const SaleAdminLangPage = React.lazy(() => import("../../pages/sales/SaleAdminLangPage"));
const EquipmentPage = React.lazy(() => import("../../pages/sales/EquipmentPage"));
const OrgLanguagePage = React.lazy(() => import("../../pages/sales/OrgLanguagePage"));

const SaleRoutes = [
    {
        element: <SalesLayout />,
        path: "org",
        children: [
            {
                path: ":orgId",
                children: [
                    {
                        path: "item",
                        children: ItemRoutes,
                    },
                    {
                        path: "admin-language",
                        element: <SaleAdminLangPage />,
                    },
                    {
                        path: "equipment",
                        element: <EquipmentPage />,
                    },
                    {
                        path: "language",
                        element: <OrgLanguagePage />,
                    },
                    {
                        path: "store",
                        children: StoreRoutes,
                    },
                ],
            },
        ],
    },
];

export default SaleRoutes;

import { array, object, string } from "yup";
const phoneRegex = /^(09)[0-9]{8}$/;
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const contactLayoutSchema = () => {
    const schema = {
        contactLayouts: array().of(
            object().shape({
                title: string().required("COMMON.Required_Input"),
                contacts: array()
                    .of(
                        object().shape({
                            type: string(),
                            value: string().test("contact-validation", "格式錯誤", function (value, context) {
                                if (!value) return true;
                                const { type } = context.parent;
                                switch (type) {
                                    case "phone":
                                        return phoneRegex.test(value);
                                    case "email":
                                        return emailRegex.test(value);
                                    case "website":
                                        try {
                                            new URL(value);
                                            return true;
                                        } catch {
                                            return false;
                                        }
                                }

                                return true;
                            }),
                        })
                    )
                    .test("at-least-one-filled", "至少需要填寫一種聯絡方式", function (values) {
                        return values?.some((item) => item.value?.trim() !== "");
                    }),
            })
        ),
    };
    return object().shape(schema);
};

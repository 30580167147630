import { useEffect, useRef } from "react";

import styles from "./SubAccountHistoryMessagesPreview.module.scss";

const WIDGET_CDN_URL = process.env.REACT_APP_WIDGET_CDN_URL;

interface IMessage {
    id: string;
    body: string;
    sender: string;
    timestamp: string;
    type: string;
}
interface IProps {
    data: IMessage[] | null;
}

const SubAccountHistoryMessagesPreview = ({ data }: IProps) => {
    // const { characterId } = useParams();
    const historyChatRef = useRef<HTMLDivElement>(null);

    // const widgetConfig = useGenerateChatWidgetConfig({
    //     characterId,
    //     customStyleOverrides: { ...AMAZE_DASHBOARD_WIDGET_STYLE_OVERRIDES },
    //     selectorId: "chat-widget-history-preview",
    // });

    const widgetConfig = {
        base: {
            amaze_key: "f6c1b25643e51656cefd55af92a49ddf",
        },
        selectorId: "chat-widget-history-preview",
        ui: {
            styleOverrides: {
                "base-font-size": "16px",
                "message-font-size": "16px",
                primary: "#060061",
                "horizontal-padding-base": "16px",
                "message-padding": "8px",
                "current-small-title-font-size": "14px",
                "current-medium-title-font-size": "16px",
                "current-large-title-font-size": "24px",
                "current-small-subtitle-font-size": "12px",
                "current-medium-subtitle-font-size": "14px",
                "current-large-subtitle-font-size": "18px",
                "current-small-content-font-size": "12px",
                "current-medium-content-font-size": "16px",
                "current-large-content-font-size": "20px",
                "current-small-button-font-size": "12px",
                "current-medium-button-font-size": "16px",
                "current-large-button-font-size": "20px",
                "current-small-hint-font-size": "10px",
                "current-medium-hint-font-size": "12px",
                "current-large-hint-font-size": "14px",
            },
            autoFocus: false,
        },
    };

    useEffect(() => {
        if (widgetConfig) {
            const script = document.createElement("script");
            script.type = "module";
            script.id = "load-chat-widget-history-preview";

            const SCRIPT = `
              import { render } from "${WIDGET_CDN_URL}/historyMessages.mjs";
              render(${JSON.stringify({ ...widgetConfig, data: data })});
              `.trim();

            script.text = SCRIPT;
            script.async = true;
            document.body.appendChild(script);
        }
        return () => {
            document.getElementById("load-chat-widget-history-preview")?.remove();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    return (
        // <div>
        <div id='chat-widget-history-preview' ref={historyChatRef} className={styles.historyPreview}></div>
        // </div>
    );
};
export default SubAccountHistoryMessagesPreview;

import { SALE_API_URL } from "../../../constants";
import { apiSlice } from "../../../services";
import { IApiRes } from "../../../types/interfaces";
import { FeatureRecommendationSettingType, OrderFlowSettingType, StoreSettingType } from "../types/interfaces";

// Request interfaces
export interface ICreateStoreReq {
    address: string;
    latitude: number;
    longitude: number;
    phone: string;
    store_name: string;
}

export interface IUpdateStoreSettingReq {
    store_id: string;
    address: string;
    latitude: number;
    longitude: number;
    phone: string;
    store_name: string;
}

export interface IUpdateOrderFlowSettingReq {
    store_id: string;
    order_distinguish_confused_items: boolean;
    specification_enhance_match: boolean;
}

export interface IUpdateFeatureRecommendationSettingReq {
    store_id: string;
    item_type: string[];
    knowledge_type: string[];
}

export interface IGetStoreSettingReq {
    store_id: string;
}

export interface IGetAccountStoresReq {
    account_id: string;
}

// Response interfaces
export interface IGetStoreSettingRes {
    data: StoreSettingType;
}

export interface IGetOrderFlowSettingRes {
    data: OrderFlowSettingType;
}

export interface IGetFeatureRecommendationSettingRes {
    data: FeatureRecommendationSettingType;
}

export interface ICreateStoreRes {
    data: {
        id: string;
        store_name: string;
    };
}

export interface IGetAccountStoresRes {
    data: {
        total_count: number;
        result: {
            id: string;
            store_name: string;
            address: string;
            phone: string;
            latitude: number;
            longitude: number;
            org_id: string;
            branch_mode: string;
            created_at: string;
            updated_at: string;
            deleted_at: null | string;
        }[];
    };
}

export interface IAccountInfoRes extends IApiRes {
    data: {
        id: string;
        org_id: string;
        email: string;
        username: string;
        display_name: string;
        roles: [
            {
                role_code: string;
                role_name: string;
                description: string;
            }
        ];
    };
}

export const storeApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // Get account info
        getAccountInfo: builder.query<IAccountInfoRes, void>({
            query: () => ({
                url: `${SALE_API_URL}/account/me`,
                method: "GET",
            }),
        }),

        // Get account stores
        getAccountStores: builder.query<IGetAccountStoresRes, IGetAccountStoresReq>({
            query: (params) => ({
                url: `/account/${params.account_id}/stores`,
                method: "GET",
            }),
            providesTags: ["SalesItems"],
        }),

        // Create store
        createStore: builder.mutation<ICreateStoreRes, ICreateStoreReq>({
            query: (body) => ({
                url: "/stores/create_record",
                method: "POST",
                body,
            }),
            invalidatesTags: ["SalesItems"],
        }),

        // Get store setting
        getStoreSetting: builder.query<IGetStoreSettingRes, IGetStoreSettingReq>({
            query: (params) => ({
                url: `/stores/${params.store_id}/setting`,
                method: "GET",
            }),
            providesTags: ["SalesItems"],
        }),

        // Get order flow setting
        getOrderFlowSetting: builder.query<IGetOrderFlowSettingRes, IGetStoreSettingReq>({
            query: (params) => ({
                url: `/stores/${params.store_id}/order_flow_setting`,
                method: "GET",
            }),
            providesTags: ["SalesItems"],
        }),

        // Get feature recommendation setting
        getFeatureRecommendationSetting: builder.query<IGetFeatureRecommendationSettingRes, IGetStoreSettingReq>({
            query: (params) => ({
                url: `/stores/${params.store_id}/feature_recommendation_setting`,
                method: "GET",
            }),
            providesTags: ["SalesItems"],
        }),

        // Update store setting
        updateStoreSetting: builder.mutation<IApiRes, IUpdateStoreSettingReq>({
            query: (params) => {
                const { store_id, ...body } = params;
                return {
                    url: `/stores/${store_id}/setting`,
                    method: "PATCH",
                    body,
                };
            },
            invalidatesTags: ["SalesItems"],
        }),

        // Update order flow setting
        updateOrderFlowSetting: builder.mutation<IApiRes, IUpdateOrderFlowSettingReq>({
            query: (params) => {
                const { store_id, ...body } = params;
                return {
                    url: `/stores/${store_id}/order_flow_setting`,
                    method: "PATCH",
                    body,
                };
            },
            invalidatesTags: ["SalesItems"],
        }),

        // Update feature recommendation setting
        updateFeatureRecommendationSetting: builder.mutation<IApiRes, IUpdateFeatureRecommendationSettingReq>({
            query: (params) => {
                const { store_id, ...body } = params;
                return {
                    url: `/stores/${store_id}/feature_recommendation_setting`,
                    method: "PATCH",
                    body,
                };
            },
            invalidatesTags: ["SalesItems"],
        }),
    }),
});

export const {
    useGetAccountInfoQuery,
    useGetAccountStoresQuery,
    useCreateStoreMutation,
    useGetStoreSettingQuery,
    useGetOrderFlowSettingQuery,
    useGetFeatureRecommendationSettingQuery,
    useUpdateStoreSettingMutation,
    useUpdateOrderFlowSettingMutation,
    useUpdateFeatureRecommendationSettingMutation,
} = storeApiSlice;

import { useParams } from "react-router-dom";

import { customToast } from "../../../components/notify/NotifyContainer";
import { getLocalValueByKey } from "../../../utils";
import { useIsProjectOwner } from "../../projects/hooks";
import { useDownloadChatHistoryWithEnterpriseMutation } from "../services/charactersEnterpriseApiSlice";
const useSubAccountChatHistoryDownload = () => {
    const [fetchDownload, isFetching] = useDownloadChatHistoryWithEnterpriseMutation();
    const { projectId } = useParams();
    const isProjectOwner = useIsProjectOwner({ projectId });
    const enterpriseAccountId = getLocalValueByKey("enterprise_user_id") as string;
    const enterpriseUsername = getLocalValueByKey("enterprise_account_id") as string;
    const handleDownload = async ({ characterId, selectedMonth }: { characterId: string; selectedMonth: string }) => {
        try {
            if (characterId && projectId) {
                const res = await fetchDownload({
                    username: enterpriseUsername,
                    enterprise_account_id: enterpriseAccountId,
                    character_id: characterId,
                    month: selectedMonth,
                    //目前只接受單月
                }).unwrap();
                if (res?.status === 1 && res?.data?.file_url) {
                    window.open(res?.data?.file_url, "_blank");
                } else {
                    customToast.error(res?.message || "Something wrong. Please try again later.");
                }
            }
        } catch (err) {
            customToast.error("Something wrong. Please try again later.");
        }
    };
    return { handleDownload, isProjectOwner, isFetching };
};
export default useSubAccountChatHistoryDownload;

import { Tab, Tabs } from "@mui/material";
import clsx from "clsx";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import { ILinkTabProps } from "../../types/interfaces";
import styles from "./MainTabs.module.scss";

interface IProps {
    currentTab: number;
    handleChange: (newValue: number) => void;
    tabsOnClick: (tabRoute: ILinkTabProps) => void;
    tabRoutes: ILinkTabProps[];
    className?: string;
    isPill?: boolean;
    isDisabled?: boolean;
}

const MainTabs = ({ currentTab, handleChange, tabsOnClick, tabRoutes, className, isPill, isDisabled }: IProps) => {
    const { t } = useTranslation();

    return (
        <Tabs
            value={currentTab}
            onChange={(event, newValue) => handleChange(newValue)}
            className={clsx(className, { [styles.pillTab]: isPill })}
            classes={{ scrollableX: styles.scrollableX }}
            variant='scrollable'
            scrollButtons={false}
            visibleScrollbar
        >
            {tabRoutes.map((tabRoute) => (
                <Tab
                    component='a'
                    onClick={(event: MouseEvent) => {
                        event.preventDefault();
                        tabsOnClick(tabRoute);
                    }}
                    label={t(tabRoute.labelKey)}
                    key={tabRoute.href}
                    sx={{ pb: 0.5, paddingTop: "5px" }}
                    data-testid={tabRoute.href}
                    className={clsx({ [styles.disabled]: isDisabled })}
                    disabled={isDisabled}
                />
            ))}
        </Tabs>
    );
};
export default MainTabs;

import { Divider, Drawer, List } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { selectCurrentUser, selectIsLogin } from "../../features/authentication/services";
import { ProjectList } from "../../features/projects/components/ProjectList";
import { useGetAccountInfoQuery } from "../../features/sales/services";
import { useAppSelector } from "../../hooks";
import { INavItem } from "../../types/interfaces";
import { isProduction } from "../../utils";
import classes from "./Sidebar.module.scss";
import SidebarMenuItem from "./SidebarMenuItem";

//import SidebarMenuItem from "./SidebarMenuItem"; // 有子項目的 sidebar menu item, 目前尚未使用到

interface IProps {
    drawerWidth: number;
    navItems: INavItem[];
    mobileOpen: boolean;
    handleDrawerToggle: () => void;
    handleVideoToggle: () => void;
    // sx:string;
}

const Sidebar = ({ navItems, drawerWidth, mobileOpen, handleDrawerToggle, handleVideoToggle }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const orgId = pathname.split("/")[3];
    const pageCategory = pathname.split("/")[4];

    const { data: accountInfo } = useGetAccountInfoQuery();
    const isLogin = useSelector(selectIsLogin);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isSaleUser = Boolean(useAppSelector(selectCurrentUser)?.salesAccountId);
    const storeName = accountInfo?.data?.display_name;
    const currentUser = accountInfo?.data.roles.map((role) => role.role_code);

    const routes = [
        {
            path: `/sale/org/${orgId}/item`,
            name: t("SALES.ItemManage"),
        },
        {
            path: `/sale/org/${orgId}/store`,
            name: t("SALES.BranchManage"),
            permission: ["ORG_ADMIN"],
            isDeveloping: true,
        },
    ];

    useEffect(() => {
        // 設置視窗寬度變化的監聽器
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setWindowWidth(newWidth);
        };

        // 添加事件監聽器
        window.addEventListener("resize", handleResize);

        // 清除事件監聽器
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleDrawerToggle, windowWidth]);

    const DrawerContent = () => {
        return (
            <>
                {isSaleUser ? (
                    //TODO: 需要新增銷售後台的內容
                    <div id='sidebar-firstChild' className={classes.drawerContent}>
                        <div className={classes.navWrapper}>
                            <div className={classes.navTitle}>{storeName}</div>
                            <List>
                                <div className={classes.navContent}>
                                    {routes.map((route) => {
                                        const isActive = pageCategory === route.path.split("/")[4];
                                        const hasPermission = route.permission
                                            ? route.permission.some((permission) => currentUser?.includes(permission))
                                            : true;
                                        if (!hasPermission) return null;
                                        if (route.isDeveloping && isProduction) return null;
                                        return (
                                            <div
                                                onClick={() => {
                                                    navigate(route.path);
                                                }}
                                                className={`${classes.navItem} ${
                                                    isActive ? classes.activeNavItem : ""
                                                }`}
                                                key={route.path}
                                            >
                                                {route.name}
                                            </div>
                                        );
                                    })}
                                </div>
                            </List>
                        </div>
                    </div>
                ) : (
                    <div id='sidebar-firstChild' className={classes.drawerContent}>
                        <div className={classes.navWrapper}>
                            <List>
                                {navItems.map((listItem) => {
                                    return <SidebarMenuItem listItem={listItem} key={listItem.href} />;
                                })}
                            </List>
                        </div>
                        {isLogin && (
                            <div className={classes.projectsNav}>
                                <div className={classes.navContent}>
                                    <div className={classes.headingText}>{t("COMMON.MY_PROJECTS")}</div>
                                    <Divider sx={{ mt: 1 }} />
                                    <ProjectList handleVideoToggle={handleVideoToggle} />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    };
    return (
        <>
            {windowWidth < 900 && (
                <Drawer
                    variant='temporary'
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: false,
                    }}
                    sx={{
                        display: { xs: "block", md: "block" },
                        //* 把常駐專案列表藏起來 */
                        // marginTop: { xs: "56px", md: "0rem" },
                        marginTop: { xs: "56px", md: "6rem" },
                    }}
                >
                    <DrawerContent />
                </Drawer>
            )}
            {/* 把常駐專案列表藏起來 */}
            {mobileOpen && (
                <Drawer
                    variant='permanent'
                    sx={{
                        width: drawerWidth,
                        display: { xs: "none", md: "block" },
                        marginTop: { xs: "0", md: "6rem" },
                    }}
                    open
                >
                    <DrawerContent />
                </Drawer>
            )}
        </>
    );
};

export default Sidebar;

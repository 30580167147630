import { array, boolean, number, object, string } from "yup";

import { SOCIAL_TYPES } from "../types";

const re =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const domainSchema = () => {
    const schema = {
        domain_name: string().required().matches(re, "URL is not valid"),
        //amazeKey: string().required(),
    };

    return object().shape(schema);
};

export const botSchema = () => {
    const schema = {
        name: string().required(),
        description: string().nullable(),
        character_id: string().required(),
        knowledge_id: string(),
        project_id: string().required(),
        channel_id: string().required(),
        social_media: number().oneOf(Object.values(SOCIAL_TYPES).map((value) => Number(value))),
    };
    return object().shape(schema);
};

export const lineAccSchema = () => {
    const schema = {
        bot_basic_id: string()
            .test("startWith@", (value, testContext) => {
                if (!value?.startsWith("@")) {
                    return testContext.createError({
                        message: "Must start with @",
                    });
                }
                return true;
            })
            .required(),
        channel_secret: string().required(),
        channel_access_token: string().required(),
    };
    return object().shape(schema);
};

export const facebookAccSchema = () => {
    const schema = {
        page_id: string().required("COMMON.Required_Input").matches(/^\d+$/, "FBSETTING.PageId_DigitOnly"),
        secret: string().required("COMMON.Required_Input"),
        //verify_token: string().required("COMMON.Required_Input"),
    };
    return object().shape(schema);
};

export const AppSettingsSchema = () => {
    const schema = {
        texts: array()
            .of(
                object().shape({
                    language: string().required(),
                    text: string(),
                })
            )
            .test("at-least-one-text", "At least one text is required", (texts) => {
                return texts?.some((item) => item.text && item.text.trim() !== "");
            })
            .required(),
        speed: string().required(),
        enable_marquee: boolean().required(),
    };

    return object().shape(schema);
};

import { IUserPermission } from "../types";

// > 內部人員臨時權限在這裡新增
const TEMP_ADMIN: IUserPermission[] = [
    {
        email: "mulit.amaze@gmail.com",
        role: "admin",
    },
    {
        email: "jan07160716@gmail.com",
        role: "admin",
    },
    {
        email: "clairema@thebarkingdog-games.com",
        role: "admin",
    },

    {
        email: "aiamaze.test@outlook.com",
        role: "admin",
    },
    {
        email: "dragonball880630@gmail.com",
        role: "admin",
    },
];

// > 客戶新增專案在這裡新增
export const USER_PERMISSION_CONFIG: IUserPermission[] = [
    ...TEMP_ADMIN,
    {
        email: "AA24014B0103.aiamaze@outlook.com", // 勝典
        role: "user",
        projectLimits: null,
    },
    {
        email: "ac24001b0101.aiamaze@outlook.com",
        role: "user",
        projectLimits: 2,
    },
    {
        email: "SA24003b0101.aiamaze@outlook.com",
        role: "user",
        projectLimits: 50,
    },
    {
        email: "youngman.aiamaze@gmail.com",
        role: "user",
        projectLimits: 2,
    },
    {
        email: "AA240312b0101.aiamaze@outlook.com",
        role: "user",
        projectLimits: 5,
    },
    {
        email: "laa23001b0101aiamaze@gmail.com",
        role: "user",
        projectLimits: 2,
    },

    {
        email: "aa10020.aiamaze@outlook.com",
        role: "user",
        projectLimits: 3,
    },
    {
        email: "honmyue@barkingdog.ai",
        role: "user",
        projectLimits: 3,
    },
    {
        email: "AA240313b0101.aiamaze@outlook.com", // 南仁湖
        role: "user",
        projectLimits: 2,
    },
    {
        email: "aa100026.aiamaze@outlook.com", // 高科大教學
        role: "user",
        projectLimits: 27,
    },
    {
        email: "aa100222.aiamaze@outlook.com", // 中央大學
        role: "user",
        projectLimits: 6,
    },
    {
        email: "aa100202.aiamaze@outlook.com", // 圓宸雲端科技有限公司
        role: "user",
        projectLimits: 9,
    },
    {
        email: "AA10020.ai@outlook.com", // 文藻 1
        role: "user",
        projectLimits: 6,
    },
    {
        email: "AA1002002.ai@outlook.com", // 文藻 2
        role: "user",
        projectLimits: 6,
    },
    {
        email: "AA1002003.ai@outlook.com", // 文藻 3
        role: "user",
        projectLimits: 6,
    },
    {
        email: "AA1002004.ai@outlook.com", // 文藻 4
        role: "user",
        projectLimits: 6,
    },
    {
        email: "AA1002005.ai@outlook.com", // 文藻 5
        role: "user",
        projectLimits: 6,
    },
    {
        email: "aa100026.aiamaze+1@outlook.com", // 高科大
        role: "user",
        projectLimits: 2,
    },
    {
        email: "aa100268.aiamaze@outlook.com", // CTCSP
        role: "user",
        projectLimits: 2,
    },
];

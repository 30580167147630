import clsx from "clsx";

import { ILinkTabProps } from "../../types/interfaces";
import classes from "./InnerTabs.module.scss";
import MainTabs from "./MainTabs";

interface IProps {
    currentTab: number;
    handleChange: (newValue: number) => void;
    tabRoutes: ILinkTabProps[];
    tabsOnClick: (tabRoute: ILinkTabProps) => void;
    className?: string;
}
const InnerTabs = ({ currentTab, handleChange, tabRoutes, tabsOnClick, className }: IProps) => {
    return (
        <MainTabs
            currentTab={currentTab}
            handleChange={handleChange}
            tabRoutes={tabRoutes}
            tabsOnClick={tabsOnClick}
            className={clsx(classes.innerCustomTab, className)}
        />
    );
};
export default InnerTabs;

import { Button, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import PageLoader from "../../components/loader/PageLoader";
import { postSalesLogin, selectCurrentUser, selectIsLogin } from "../../features/authentication/services";
import { useLoginSaleAccountMutation } from "../../features/sales/services";
import { useAppDispatch, useAppSelector } from "../../hooks";
import styles from "./SalesLoginPage.module.scss";

const SalesLoginPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isLogin = useSelector(selectIsLogin);
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectCurrentUser);

    const [loginInputs, setLoginInputs] = useState({ email: "", password: "" });

    const [loginSale, { isLoading }] = useLoginSaleAccountMutation();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLoginInputs((prev) => ({ ...prev, [name]: value }));
    };

    const handleLoginBtn = async () => {
        try {
            const res = await loginSale(loginInputs).unwrap();

            if (res?.data?.access_token && res?.data?.refresh_token) {
                dispatch(
                    postSalesLogin({
                        accessToken: res?.data?.access_token,
                        refreshToken: res?.data?.refresh_token,
                    })
                );
            }
        } catch (error) {
            toast.error("login user: account or password is not match");
        }
    };

    useEffect(() => {
        if (isLogin && currentUser) {
            navigate(`/sale/org/${currentUser.org_id}/item`);
        } else {
            navigate("/sale/login");
        }
    }, [navigate, isLogin, currentUser]);

    if (isLoading) {
        return <PageLoader loading={isLoading} />;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.loginContainer}>
                <h2> {t("COMMON.Login")}</h2>
                <TextField
                    id='outlined-basic'
                    label='account'
                    name='email'
                    value={loginInputs.email}
                    onChange={handleChange}
                    fullWidth
                />
                <TextField
                    name='password'
                    onChange={handleChange}
                    value={loginInputs.password}
                    id='outlined-password-input'
                    label='Password'
                    type='password'
                    autoComplete='current-password'
                    fullWidth
                />
                <Button variant='contained' onClick={handleLoginBtn}>
                    {t("COMMON.Login")}
                </Button>
            </div>
        </div>
    );
};

export default SalesLoginPage;

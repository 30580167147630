import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL || "/api";

export const apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

// Add a request interceptor
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Handle errors (e.g., redirect to login on 401)
        if (error.response?.status === 401) {
            // Redirect to login page or refresh token
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { customToast } from "../../../components/notify";
export type PickerDate = Dayjs | null;

export type DateRange = {
    started_at: PickerDate;
    ended_at: PickerDate;
};
export interface IDateProps {
    date: PickerDate;
    setDate: Dispatch<SetStateAction<PickerDate>>;
    dateRange: DateRange;
    handleDateRange: (date: DateRange) => void;
    handleStartDate: (date: PickerDate) => void;
    handleEndDate: (date: PickerDate) => void;
    rangeLimitDays?: number;
}

const DateContext = createContext({} as IDateProps);

dayjs.extend(utc);

const initialDateRange = {
    started_at: dayjs.utc().startOf("month"),
    ended_at: dayjs.utc(),
};

export const useDateContext = () => useContext(DateContext);

interface DateProviderProps {
    children?: ReactNode;
    rangeLimitDays?: number;
}

export const DateProvider = ({ children, rangeLimitDays }: DateProviderProps) => {
    const [date, setDate] = useState<PickerDate>(dayjs.utc());
    const [dateRange, setDateRange] = useState<DateRange>(initialDateRange);
    const { t } = useTranslation();
    const handleStartDate = (date: PickerDate) => {
        const endedDate = dateRange.ended_at?.unix();
        const startedDate = date?.unix();
        if (!startedDate || !endedDate) return;
        if (startedDate > endedDate) {
            setDateRange({ ...dateRange, started_at: dayjs(dateRange.ended_at).subtract(1, "day") });
            customToast.error("Start date is greater than end date");
            return;
        }
        if (rangeLimitDays && dateRange.started_at && dateRange.ended_at) {
            if (Math.abs(dateRange.started_at.diff(dateRange.ended_at, "day")) > rangeLimitDays) {
                setDateRange({ ...dateRange, started_at: dayjs(dateRange.ended_at).subtract(rangeLimitDays, "day") });
                customToast.error(t("CH.Chat_OverDays"));
                return;
            }
        }
        setDateRange({ ...dateRange, started_at: date });
    };
    const handleEndDate = (date: PickerDate) => {
        const startedDate = dateRange.started_at?.unix();
        const endedDate = date?.unix();
        if (!startedDate || !endedDate) return;
        if (endedDate < startedDate) {
            setDateRange({ ...dateRange, ended_at: dayjs.utc().endOf("day") });
            customToast.error("End date is less than start date");
            return;
        }
        if (dateRange.started_at && dateRange.ended_at) {
            if (rangeLimitDays && Math.abs(dateRange.started_at.diff(dateRange.ended_at, "day")) > rangeLimitDays) {
                setDateRange({ ...dateRange, ended_at: dayjs(dateRange.started_at).add(rangeLimitDays, "day") });
                customToast.error(t("CH.Chat_OverDays"));
                return;
            }
            setDateRange({ ...dateRange, ended_at: date });
        }
    };
    const handleDateRange = ({ started_at, ended_at }: DateRange) => {
        setDateRange({
            started_at,
            ended_at,
        });
    };
    return (
        <DateContext.Provider
            value={{ rangeLimitDays, date, setDate, dateRange, handleDateRange, handleStartDate, handleEndDate }}
        >
            {children}
            <Outlet />
        </DateContext.Provider>
    );
};

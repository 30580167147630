import { createContext, ReactNode, useCallback, useContext, useState } from "react";

import { ILanguageFormState, Layout } from "../types";
interface ILanguageFormProvider {
    formDataMap: Map<string, ILanguageFormState>;
    saveLanguageState: (lang: string, state: ILanguageFormState) => void;
    isDirty: boolean;
    handleDirty: (bool: boolean) => void;
    getLanguageState: (lang: string) => ILanguageFormState | undefined;
    clearLanguageState: (lang: string) => void;
    clearAllStates: () => void;
    layoutCount: Record<Layout["type"], number>;
    countLayout: (type: Layout["type"]) => void;
    updateLayoutCount: (counts: Record<Layout["type"], number>) => void;
    incrementLayoutCount: (type: Layout["type"]) => void;
    decrementLayoutCount: (type: Layout["type"]) => void;
}
const LAYOUT_TYPE = {
    text: 0,
    contact: 0,
    sharedSpec: 0,
};
const LanguageFormContext = createContext<ILanguageFormProvider | null>(null);

export const LanguageFormProvider = ({ children }: { children: ReactNode }) => {
    const [formDataMap, setFormDataMap] = useState<Map<string, ILanguageFormState>>(new Map());
    const [layoutCount, setLayoutCount] = useState<Record<Layout["type"], number>>(LAYOUT_TYPE);
    const [isDirty, setIsDirty] = useState(false);
    const saveLanguageState = useCallback((lang: string, state: ILanguageFormState) => {
        setFormDataMap((prev) => {
            const newMap = new Map(prev);
            newMap.set(lang, state);
            return newMap;
        });
    }, []);
    const updateLayoutCount = (counts: Record<Layout["type"], number>) => {
        setLayoutCount(counts);
    };

    // 增加特定類型的 layout 數量
    const incrementLayoutCount = (type: Layout["type"]) => {
        setLayoutCount((prev) => ({
            ...prev,
            [type]: prev[type] + 1,
        }));
    };

    // 減少特定類型的 layout 數量
    const decrementLayoutCount = (type: Layout["type"]) => {
        setLayoutCount((prev) => ({
            ...prev,
            [type]: Math.max(0, prev[type] - 1),
        }));
    };
    const countLayout = (type: Layout["type"]) => {
        setLayoutCount((prev) => ({
            ...prev,
            [type]: prev[type] + 1,
        }));
    };
    const handleDirty = (bool: boolean) => {
        setIsDirty(bool);
    };

    const getLanguageState = (lang: string) => formDataMap.get(lang);

    const clearLanguageState = (lang: string) => {
        setFormDataMap((prev) => {
            const newMap = new Map(prev);
            newMap.delete(lang);
            return newMap;
        });
    };

    const clearAllStates = () => {
        setFormDataMap(new Map());
    };

    return (
        <LanguageFormContext.Provider
            value={{
                formDataMap,
                saveLanguageState,
                isDirty,
                handleDirty,
                getLanguageState,
                clearLanguageState,
                clearAllStates,
                layoutCount,
                countLayout,
                updateLayoutCount,
                incrementLayoutCount,
                decrementLayoutCount,
            }}
        >
            {children}
        </LanguageFormContext.Provider>
    );
};

export const useLanguageFormCtx = () => {
    const context = useContext(LanguageFormContext);
    if (!context) {
        throw new Error("useLanguageForm must be used within a LanguageFormProvider");
    }
    return context;
};

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
// import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { useTranslation } from "react-i18next";

import { MButton } from "../../../../components/button";
import { PageLoader } from "../../../../components/loader";
import { useSubAccountChatHistoryDownload } from "../../hooks";

const SubAccountChatHistoryDownloadBtn = ({
    characterId,
    selectedMonth,
}: {
    characterId: string;
    selectedMonth: string;
}) => {
    const { t } = useTranslation();
    const { handleDownload, isFetching } = useSubAccountChatHistoryDownload();
    const btnText = t("CH.Chat_History_Download_Month");
    const btnIcon = <DownloadRoundedIcon sx={{ fontSize: 24 }} />;

    return (
        <>
            <PageLoader loading={isFetching.isLoading} />
            <MButton
                btnText={btnText}
                icon={btnIcon}
                type='button'
                color='info'
                onClick={() => handleDownload({ characterId, selectedMonth })}
                sx={{
                    marginLeft: 1,
                    height: "fit-content",
                    flexWrap: "nowrap",
                    minWidth: "160px",
                }}
            />
        </>
    );
};
export default SubAccountChatHistoryDownloadBtn;

import { useTranslation } from "react-i18next";
import { object, string } from "yup";

import { HINT_A_LIMIT, HINT_Q_LIMIT, HINT_Q_LIMIT_VI } from "../../characters/constants";
import { HINT_A_LIMIT_VI } from "../constants/constants";

export const HintsSchema = (language?: string | null) => {
    const { t } = useTranslation();

    const hintQLimit = language === "vi-VN" ? HINT_Q_LIMIT_VI : HINT_Q_LIMIT;
    const hintALimit = language === "vi-VN" ? HINT_A_LIMIT_VI : HINT_A_LIMIT;
    const schema = {
        hintQ: string()
            .required(`${t("COMMON.Required_Input")}`)
            .min(1)
            .max(hintQLimit, `${t("COMMON.Word_Limit")} ${hintQLimit.toString()} `),
        hintA: string().max(hintALimit, `${t("COMMON.Word_Limit")} ${hintALimit.toString()}`),
    };

    return object().shape(schema);
};

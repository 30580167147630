import { apiSlice } from "../../../services";
import { IApiIdReq, IApiQueryParams } from "../../../types/interfaces";
import {
    IAmazeKeyReq,
    IAmazeKeyRes,
    IBotRes,
    IBotsRes,
    ICreateBotReq,
    ICreateBotRes,
    IGetAllBotReq,
    IGetCharacterBotsReq,
} from "../types";

export const botSettingsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createBot: builder.mutation<ICreateBotRes, ICreateBotReq>({
            query: (reqData) => ({
                url: "/create_bot",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Bot"],
        }),
        //updateBot: builder.mutation<
        //    { success: boolean; projects: string },
        //    IUpdateBotReq
        //>({
        //    query: (reqData) => ({
        //        url: "/update_bot",
        //        method: "POST",
        //        body: { ...reqData },
        //    }),
        //    invalidatesTags: ["Bot"],
        //}),
        getAllBots: builder.query<IBotsRes, IGetAllBotReq>({
            query: (params) => ({
                url: "/bots",
                params,
            }),
            providesTags: ["Bot"],
        }),
        // * get all character bots by socialType
        getCharacterBots: builder.query<IBotsRes, IGetCharacterBotsReq>({
            query: (reqData) => ({
                url: "/get_character_bots",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["Bot"],
        }),

        getBot: builder.query<IBotRes, IApiQueryParams>({
            query: (params) => ({
                url: "/bot",
                params,
            }),
            providesTags: ["Bot"],
        }),
        deleteBot: builder.mutation<{ success: boolean }, IApiIdReq>({
            query: (reqData) => ({
                url: "/delete_bot",
                method: "DELETE",
                body: { ...reqData },
            }),
            invalidatesTags: ["Bot"],
        }),

        getAmazeKey: builder.query<IAmazeKeyRes, IAmazeKeyReq>({
            query: (reqData) => ({
                url: "/check_gen_amaze_key",
                method: "POST",
                body: { ...reqData },
            }),
        }),
    }),
});

export const {
    useCreateBotMutation,
    //useUpdateBotMutation,
    useDeleteBotMutation,
    useGetAllBotsQuery,
    useGetBotQuery,
    useGetAmazeKeyQuery,
    useGetCharacterBotsQuery,
} = botSettingsApiSlice;

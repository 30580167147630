import { yupResolver } from "@hookform/resolvers/yup";
import {
    createContext,
    Dispatch,
    MutableRefObject,
    ReactNode,
    SetStateAction,
    useContext,
    useReducer,
    useRef,
    useState,
} from "react";
import {
    FieldArrayWithId,
    useFieldArray,
    UseFieldArrayAppend,
    UseFieldArrayRemove,
    useForm,
    UseFormReturn,
} from "react-hook-form";
import { useParams } from "react-router-dom";

import { useDragMonitor } from "../../../../../../hooks";
import { useGetAllSharedSpecGroupByOrgIdQuery } from "../../sharedSpecGroup/services/sharedSpecGroupSlice";
import { GetAllSharedSpecGroupByOrgIdRes } from "../../sharedSpecGroup/types/interfaces";
import { DEFAULT_STATE } from "../constants";
import useLanguageFormState from "../hooks/useLanguageFormState";
import reducer from "../ItemDetailsReducer";
import { Action, IContactLayoutValues, ISharedSpecLayout, ITextLayoutValues, Layout } from "../types";
import { contactLayoutSchema } from "../validation";
import { textLayoutSchema } from "../validation/textLayout";
import { useLanguageFormCtx } from "./LanguageFormCtx";
interface IProps {
    children: ReactNode;
    hasChangedPage: boolean;
    setHasChangedPage: Dispatch<SetStateAction<boolean>>;
    prevTab: MutableRefObject<number>;
}
interface ILayoutFormMethods {
    contactLayoutMethods: UseFormReturn<IContactLayoutValues>;
    textLayoutMethods: UseFormReturn<ITextLayoutValues>;
}
interface ILayoutFields {
    textLayoutFields: FieldArrayWithId<ITextLayoutValues, "textLayouts", "id">[];
    appendTextLayout: UseFieldArrayAppend<ITextLayoutValues, "textLayouts">;
    removeTextLayout: UseFieldArrayRemove;
    contactLayoutFields: FieldArrayWithId<IContactLayoutValues, "contactLayouts", "id">[];
    appendContactLayout: UseFieldArrayAppend<IContactLayoutValues, "contactLayouts">;
    removeContactLayout: UseFieldArrayRemove;
}
interface ISharedSpecState {
    sharedSpecLayout: ISharedSpecLayout["sharedSpecLayout"];
    setSharedSpecLayout: Dispatch<SetStateAction<ISharedSpecLayout["sharedSpecLayout"]>>;
}
interface ILayoutState {
    selectedIds: { type: Layout["type"]; id: string }[];
    setSelectedIds: Dispatch<SetStateAction<{ type: Layout["type"]; id: string }[]>>;
    canDelete: boolean;
    sortable: boolean;
    selectable: boolean;
    overLimitation: boolean;
    openSharedSpecModal: boolean;
    setOpenSharedSpecModal: Dispatch<SetStateAction<boolean>>;
}
interface ISortState {
    resetSorting: boolean;
    setResetSorting: Dispatch<SetStateAction<boolean>>;
    sortingItems: { id: string; type: Layout["type"] }[];
    setSortingItems: Dispatch<SetStateAction<{ id: string; type: Layout["type"] }[]>>;
    sortedItems: { id: string; type: Layout["type"] }[];
    sortingContainerRef: MutableRefObject<null>;
    isSorted: boolean;
}
interface IProviders extends ILayoutFormMethods, ILayoutFields, ISharedSpecState, ILayoutState, ISortState {
    dispatch: Dispatch<Action>;
    isDisabled: (isEdit?: boolean) => boolean;
    apiSharedSpecs: GetAllSharedSpecGroupByOrgIdRes["data"]["result"];
    isSubmitDisabled: boolean;
    setIsSubmitDisabled: Dispatch<SetStateAction<boolean>>;
}

const DetailsCtx = createContext({} as IProviders);
const DetailsProvider = ({ children, ...syncLanguageState }: IProps) => {
    const { orgId } = useParams();
    const [sharedSpecLayout, setSharedSpecLayout] = useState<ISharedSpecLayout["sharedSpecLayout"]>([]);
    const [selectedIds, setSelectedIds] = useState<{ type: Layout["type"]; id: string }[]>([]);
    const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);
    const [sortingItems, setSortingItems] = useState<{ id: string; type: Layout["type"] }[]>([]);
    const [resetSorting, setResetSorting] = useState(false);
    const [openSharedSpecModal, setOpenSharedSpecModal] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const { data: sharedSpecs } = useGetAllSharedSpecGroupByOrgIdQuery({
        org_id: orgId ?? "",
        limit: 10,
        page: 1,
    });
    const sortingContainerRef = useRef(null);
    const languageFormMethods = useLanguageFormCtx();
    const canDelete = selectedIds.length > 0;
    const contactLayoutMethods = useForm<IContactLayoutValues>({
        defaultValues: { contactLayouts: [] },
        resolver: yupResolver(contactLayoutSchema()),
        mode: "all",
    });
    const textLayoutMethods = useForm<ITextLayoutValues>({
        defaultValues: { textLayouts: [] },
        resolver: yupResolver(textLayoutSchema()),
        mode: "all",
    });
    const {
        fields: textLayoutFields,
        append: appendTextLayout,
        remove: removeTextLayout,
        replace: replaceTextLayout,
    } = useFieldArray({
        control: textLayoutMethods.control,
        name: "textLayouts",
    });
    const {
        fields: contactLayoutFields,
        append: appendContactLayout,
        remove: removeContactLayout,
        replace: replaceContactLayout,
    } = useFieldArray({
        control: contactLayoutMethods.control,
        name: "contactLayouts",
    });

    const { isDirty: isTextLayoutDirty, isValid: isTextLayoutValid } = textLayoutMethods.formState;
    const { isDirty: isContactLayoutDirty, isValid: isContactLayoutValid } = contactLayoutMethods.formState;
    const hasLayouts = {
        text: textLayoutFields.length > 0,
        contact: contactLayoutFields.length > 0,
        sharedSpec: sharedSpecLayout.length > 0,
    };

    const isLayoutValid = {
        text: isTextLayoutDirty && isTextLayoutValid,
        contact: isContactLayoutDirty && isContactLayoutValid,
        sharedSpec: sharedSpecLayout.every((item) => item.value),
    };

    const isDisabled = (isEdit = false) => {
        const activeLayouts = Object.entries(hasLayouts)
            .filter(([_, exists]) => exists)
            .map(([key]) => key);

        if (activeLayouts.length === 0) return true;

        if (isEdit) {
            const valid = isTextLayoutValid && isContactLayoutValid && isLayoutValid.sharedSpec;
            const dirty = isTextLayoutDirty || isContactLayoutDirty;
            const shouldDisabled = !valid || !dirty;
            return shouldDisabled;
        }
        return !activeLayouts.every((layout) => isLayoutValid[layout as keyof typeof isLayoutValid]);
    };
    const { items: sortedItems, isDirty: isSorted } = useDragMonitor({
        el: sortingContainerRef.current,
        dndItems: sortingItems,
        reset: resetSorting,
        setReset: setResetSorting,
        isClient: true,
    });
    useLanguageFormState({
        textLayoutMethods,
        contactLayoutMethods,
        sharedSpecLayout,
        setSharedSpecLayout,
        replaceTextLayout,
        replaceContactLayout,
        ...languageFormMethods,
        ...syncLanguageState,
    });
    return (
        <DetailsCtx.Provider
            value={{
                dispatch,
                isDisabled,
                contactLayoutMethods,
                textLayoutMethods,
                textLayoutFields,
                appendTextLayout,
                contactLayoutFields,
                appendContactLayout,
                sharedSpecLayout,
                setSharedSpecLayout,
                removeTextLayout,
                removeContactLayout,
                selectedIds,
                setSelectedIds,
                canDelete,
                resetSorting,
                setResetSorting,
                sortingItems,
                setSortingItems,
                sortedItems,
                sortingContainerRef,
                isSorted,
                apiSharedSpecs: sharedSpecs?.data?.result ?? [],
                openSharedSpecModal,
                setOpenSharedSpecModal,
                isSubmitDisabled,
                setIsSubmitDisabled,
                ...state,
            }}
        >
            {children}
        </DetailsCtx.Provider>
    );
};
export default DetailsProvider;
export const useDetailsCtx = () => useContext(DetailsCtx);

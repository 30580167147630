import React from "react";

const SharedSpecPage = React.lazy(() => import("../../pages/sales/sharedSpec/SharedSpecPage"));
const SalesItemManagePage = React.lazy(() => import("../../pages/sales/item/ManagePage"));
const SharedSpecCategoryPage = React.lazy(() => import("../../pages/sales/sharedSpec/SharedSpecCategoryPage"));
const ExtraInfoPage = React.lazy(() => import("../../pages/sales/item/ExtraInfoPage"));
const CategoryManagePage = React.lazy(() => import("../../pages/sales/category/ManagePage"));
const CategoryCreatePage = React.lazy(() => import("../../pages/sales/category/CategoryCreatePage"));
const ItemCreatePage = React.lazy(() => import("../../pages/sales/item/ItemCreatePage"));
const ItemDetailsPage = React.lazy(() => import("../../pages/sales/item/ItemDetailsPage"));
const CompletedPage = React.lazy(() => import("../../pages/sales/item/CompletedPage"));
const SharedSpecCategoryLayout = React.lazy(
    () => import("../../features/sales/components/item/sharedSpecCategory/layout/SharedSpecCategoryLayout")
);
const ItemRoutes = [
    {
        path: "",
        children: [
            { path: "", element: <SalesItemManagePage /> },

            {
                path: "create",
                children: [
                    {
                        path: "",
                        element: <ItemCreatePage />,
                    },
                    // {
                    //     path: ":itemId/details",
                    //     element: <ItemDetailsPage />,
                    // },
                ],
            },
            {
                path: ":itemId", //:itemId
                children: [
                    {
                        path: "",
                        element: <ItemCreatePage />,
                    },
                    {
                        path: "details",
                        element: <ItemDetailsPage />,
                    },
                ],
            },
            {
                path: "completed",
                element: <CompletedPage />,
            },
        ],
    },
    {
        path: "category",
        children: [
            {
                path: "",
                element: <CategoryManagePage />,
            },
            {
                path: "create",
                element: <CategoryCreatePage />,
            },
            {
                path: ":categoryId",
                element: <CategoryCreatePage />,
            },
        ],
    },
    {
        path: "shared-spec",
        element: <SharedSpecPage />,
    },
    {
        path: "shared-spec-category",
        element: <SharedSpecCategoryLayout />,
        children: [
            { path: "", element: <SharedSpecCategoryPage /> },
            { path: ":sharedSpecCategoryId", element: <SharedSpecCategoryPage /> },
        ],
    },
    { path: "extra-info", element: <ExtraInfoPage /> },
];

export default ItemRoutes;

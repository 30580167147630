import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemText } from "@mui/material";
import { useState } from "react";

import { INavItem } from "../../types/interfaces";
import MenuItemComp from "./MenuItemComp";
import classes from "./SidebarMenuItem.module.scss";

interface IProps {
    listItem: INavItem;
}
const SidebarMenuItem = ({ listItem }: IProps) => {
    const isExpandable = listItem.items && listItem.items.length > 0;
    const [open, setOpen] = useState<boolean>(false);

    if (!listItem.showInSidebar) {
        return <></>;
    }
    return (
        <>
            <MenuItemComp listItem={listItem} handleSubMenuClick={() => setOpen(!open)}>
                <ListItemText primary={listItem.name} className={classes.itemText} />
                {isExpandable && !open && <ExpandMore />}
                {isExpandable && open && <ExpandLess />}
            </MenuItemComp>
            {isExpandable ? (
                <Collapse in={open} timeout='auto'>
                    <List
                        component='div'
                        disablePadding
                        sx={{
                            marginLeft: (theme) => theme.spacing(1),
                        }}
                    >
                        {listItem?.items?.map((item, index) => (
                            <SidebarMenuItem listItem={item} key={index} />
                        ))}
                    </List>
                </Collapse>
            ) : null}
        </>
    );
};
export default SidebarMenuItem;

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { RegularContainer } from "../../../../components/container";
import DateTimeDropdown from "../../../../components/dateTimeDropdown/DateTimeDropdown";
import { DropdownBox } from "../../../../components/dropDownBox";
import { BouncingDots } from "../../../../components/loader";
import { useGetSubAccountChatHistory } from "../../hooks";
import { SubAccountChatHistoryDownloadBtn } from ".";
import styles from "./SubAccountChatHistoryPreview.module.scss";
import SubAccountHistoryList from "./SubAccountHistoryList";
import SubAccountHistoryMessagesPreview from "./SubAccountHistoryMessagesPreview";

const SubAccountChatHistoryPreview = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const todayEndOfTheDay = dayjs().endOf("day");

    const {
        setSelectedMonth,
        selectedMonth,
        totalCount,
        handlePageChange,
        pageCount,
        currentPage,
        handleEditClick,
        formattedMessages,
        setSelectedDate,
        selectedDate,
        selectedCharacter,
        historyData,
        characterOptions,
        handleCharacterChange,
        chatLoading,
        error,
    } = useGetSubAccountChatHistory();
    return (
        <RegularContainer>
            <RegularContainer.Content>
                <div className={styles.previewLayout}>
                    <div className={styles.handleSection}>
                        <DropdownBox
                            category={selectedCharacter}
                            options={characterOptions || []}
                            onChange={handleCharacterChange}
                            dropdownTitle='CH.Selector'
                            classes={{ wrapper: styles.dropdownWrapper, select: styles.dropdownSelect }}
                        />
                        <div className={styles.timeRangeContainer}>
                            <DateTimeDropdown
                                title={t("CH.DateSelect") || "Select date"}
                                defaultValue={todayEndOfTheDay}
                                value={selectedDate}
                                handleChange={(newValue) => setSelectedDate(newValue || todayEndOfTheDay)}
                                format='YYYY / MM / DD'
                                views={["year", "month", "day"]}
                                maxDate={todayEndOfTheDay}
                                disableFuture={true}
                            />
                        </div>
                        <div className={styles.timeRangeContainer}>
                            <DateTimeDropdown
                                title={t("CH.MonthSelect") || "Select date"}
                                defaultValue={todayEndOfTheDay}
                                value={selectedMonth}
                                handleChange={(newValue) => setSelectedMonth(newValue || todayEndOfTheDay)}
                                format='YYYY / MM '
                                views={["year", "month"]}
                                maxDate={todayEndOfTheDay}
                                disableFuture={true}
                                openTo='month'
                            />
                            <div className={styles.btnGroup}>
                                <SubAccountChatHistoryDownloadBtn
                                    characterId={selectedCharacter}
                                    selectedMonth={selectedMonth.format("YYYY-MM")}
                                />
                            </div>
                        </div>
                        <div className={styles.historyListContainer}>
                            {chatLoading ? (
                                <div
                                    style={{
                                        width: "100%",
                                        minHeight: "370px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <BouncingDots />
                                </div>
                            ) : (
                                <SubAccountHistoryList
                                    histories={historyData}
                                    handleEditClick={handleEditClick}
                                    pageCount={pageCount}
                                    searchParams={searchParams}
                                    handlePageChange={handlePageChange}
                                    currentPage={currentPage}
                                    historyCount={totalCount as number}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.historySection}>
                        {/* {historyCount !== 0 && ( */}
                        <div className={styles.historyPreviewContainer}>
                            {formattedMessages ? (
                                <SubAccountHistoryMessagesPreview data={formattedMessages} />
                            ) : chatLoading ? (
                                <BouncingDots />
                            ) : (
                                t("CH.CheckHistory")
                            )}
                        </div>
                        {/* )} */}
                        {error && <div>something error, please try later...</div>}
                    </div>
                </div>
            </RegularContainer.Content>
        </RegularContainer>
    );
};
export default SubAccountChatHistoryPreview;
